import * as React from 'react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

import { graphql } from 'gatsby';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import Lightbox from 'react-awesome-lightbox';
// You need to import the CSS only once
import 'react-awesome-lightbox/build/style.css';

const IndexPage = ({ data }) => {
  const { allMarkdownRemark } = data;
  const { nodes } = allMarkdownRemark;

  const [lightboxVisible, setLightboxVisible] = React.useState(false);

  const [lightboxIndex, setLightboxIndex] = React.useState(0);

  let images = nodes.map((node) => node.frontmatter.thumbnail);

  return (
    <div>
      <h1>Bereuter 10 - Chronik</h1>
      {lightboxVisible && (
        <Lightbox
          images={images}
          startIndex={lightboxIndex}
          onClose={() => setLightboxVisible(false)}
          onNavigateImage={(index) => console.log(index)}
        />
      )}

      <VerticalTimeline>
        {nodes.map((node, index) => {
          return (
            <VerticalTimelineElement
              key={node.id}
              className='vertical-timeline-element'
              contentStyle={{ background: '#63798b', color: '#fff' }}
              contentArrowStyle={{
                borderRight: '7px solid  #63798b',
              }}
              date={`${format(new Date(node.frontmatter.date), 'd.M.Y', {
                locale: de,
              })}`}
              iconStyle={{ background: '#63798b', color: '#63798b' }}
            >
              <img
                className='thumbnail'
                src={node.frontmatter.thumbnail}
                onClick={() => {
                  setLightboxIndex(index);
                  setLightboxVisible(true);
                }}
              />
              <h3 className='vertical-timeline-element-title'>
                {node.frontmatter.title}
              </h3>
              <h4 className='vertical-timeline-element-subtitle'>
                {node.frontmatter.body}
              </h4>
              <div dangerouslySetInnerHTML={{ __html: node.html }} />
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
};

export const pageQuery = graphql`
  {
    allMarkdownRemark {
      nodes {
        frontmatter {
          date
          thumbnail
          title
        }
        html
        id
      }
    }
  }
`;

export default IndexPage;
